<template>
  <div class="line-view container">
    <div v-if="naverUserId != null">
      <NaverList />
    </div>
    <div v-else>
      <NaverLoginButton @result="result" />
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'Naver',
  components: {
    NaverList: () => import('@/components/naver/NaverList'),
    NaverLoginButton: () => import('@/components/naver/NaverLoginButton'),
  },
  computed: {
    webId() {
      return this.$store.state.solid.webId;
    },
    naverUserId() {
      return this.$store.state.naver.naverUserId;
    },
  },
  data() {
    return {
      //lineUserId: null
      // clientId: '',
      // clientSecret: '',
      // callbackUri: ''
    };
  },
  created: function() {
    // line login check
    if (localStorage.getItem('naver_access_token') != null) {
      const token = localStorage.getItem('naver_access_token');
      this.$store.dispatch('naver/getNaverUser', token);
    }
  },
  methods: {
    result(res) {
      console.log('res:' + res);
      this.$router.go();
    },
  },
};
</script>

<style>
.line-view {
  text-align: left;
  padding: 0;
}
</style>
